<template>
    <div>
        <SenderIDTable :parent="parent"/>
    </div>
  </template>
  
  <script>

  import SenderIDTable from './senderidTable.vue'
  import appConfig from '@/app.config'
  export default {
      page: {
          title: 'autoApproved sender IDs',
          meta: [{ name: 'description', content: appConfig.description }],
      },
      name: 'auto-approved-sender_ids',
      components: {
          SenderIDTable
      },
      data() {
          return {
            parent:{
                name:'autoApproved',
                storeDataName: 'autoApprovedSenderIDs',
                from: 'autoApproved',
                status:'auto-approved'
            }
        }
      },
      methods: {
  
      },
      computed: {
  
      },
      mounted() {
  
      }
  }
  </script>
  
  <style>
  
  </style>